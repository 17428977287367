import store from "@/store/index";

// let dominio_gestao = store.getters.layoutConfig("distribuidor.dominio_gestao");
// let tipo_conexao = store.getters.layoutConfig("distribuidor.tipo_conexao");
// let distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
// let   "../v2";

// if (dominio_gestao == "localhost:8080") {
//   link_v2 = tipo_conexao + "://localhost/acampa-gestao-pra/v2";
// } else {
//   link_v2 = "../v2";
// }

// let link_v3 = "../v3";
// let menuCalculadora = {};

// if (
//   distribuidor == "atria" ||
//   distribuidor == "homologacao" ||
//   distribuidor == "localhost"
// ) {
//   menuCalculadora = {
//     heading: "Calculadora de Preços",
//     route: "calculadora-precos",
//     nivelCargo: [-1],
//     // fontIcon: "fa-th-large",
//     svgIcon: "./media/icons/duotune/shopping/calculator.svg",
//   };
// }
//comentado temporariamente
const DocMenuConfig = [
  {
    route: "/",
    pages: 
    [
      // {
      //   sectionTitle: "Empresas",
      //   route: "/subscriptions1",
      //   nivelCargo: [2, 3],
      //   svgIcon: "./media/icons/duotune/abstract/abs027.svg",
      //   sub: [
      //     {
      //       heading: "Grupos",
      //       route: "/grupolist.php",
      //       nivelCargo: [-1, 2],
      //     },
      //     {
      //       heading: "Concessionárias",
      //       route: "/concessionarialist.php",
      //       nivelCargo: [-1, 2],
      //     },
      //     {
      //       heading: "Consultores",
      //       route: "/consultorconcessionarialist.php",
      //       nivelCargo: [-1, 2],
      //     },
      //     {
      //       heading: "Mecânicos",
      //       route: "/mecanicoconcessionarialist.php",
      //       nivelCargo: [-1, 2],
      //     },
      //     {
      //       heading: "Serviços",
      //       route: "/servicoconcessionarialist.php",
      //       nivelCargo: [-1, 2],
      //     },
      //     {
      //       heading: "Usuários",
      //       route: "/usuariolist.php",
      //       nivelCargo: [3],
      //     },
      //   ],
      // },
      // {
      //   sectionTitle: "Gestão da indicação",
      //   route: "/filtroVeiculos",
      //   nivelCargo: [100, 150],
      //   fontIcon: "fa-chart-area",
      //   svgIcon: "./media/icons/duotune/graphs/gra003.svg",
        // sub: [
        //   {
        //     heading: "Lancamento Mensal",
        //     route: "/lancamentolist.php",
        //     nivelCargo: [100, 150],
        //   },
        //   {
        //     heading: "Relatório em Excel",
        //     route: "/relatorioslist.php",
        //     nivelCargo: [-1, 2],
        //   },
        //   {
        //     heading: "Importação Workflow",
        //     route: "/lancamentoaddlote_workflow.php",
        //     nivelCargo: [-1, 2],
        //   },
        //   {
        //     heading: "Integração DMS",
        //     route: "/consultamensalintegracaolist.php",
        //     nivelCargo: [-1, 2],
        //   },
        // ],
      // },
      // {
      //   sectionTitle: "Gestão de lubrificantes",
      //   route: "/gestaoLubrificantes",
      //   nivelCargo: [100, 150],
      //   fontIcon: "fa-chart-area",
      //   svgIcon: "./media/icons/duotune/graphs/gra003.svg",
      // },
      // {
      //   heading: "Grupos",
      //   route: "/controleestoquelist.php",
      //   svgIcon: "./media/icons/duotune/ecommerce/ecm006.svg",
      //   nivelCargo: [100, 150],
      // },
      // {
        // heading: "Planos",
        // route: "/cardapioconcessionarialist.php",
        // svgIcon: "./media/icons/duotune/general/gen054.svg",
        // nivelCargo: [-1, 2],
        /*  sub: [
          {
            heading: "Acampa Administração",
            route:   "/cardapioconcessionarialist.php",
            nivelCargo: [-1, 2],
          },
          {
            heading: "Acampa Cliente",
            route:   "/oferta/",
            nivelCargo: [-1, 2],
          },
        ], */
      // },
      // {
      //   sectionTitle: "Usuários",
      //   nivelCargo: [1, 3],
      //   route: "/oficina-digital",
      //   // fontIcon: "fa-desktop",
      //   svgIcon: "./media/icons/duotune/general/tools-duotone.svg",
      //   sub: [
      //     {
      //       heading: "Em andamento",
      //       route: "/oficina-digital/gerenciar",
      //       nivelCargo: [100, 150, 3],
      //     },
      //     {
      //       heading: "Relatório Completo",
      //       route: "/oficina-digital/finalizados",
      //       nivelCargo: [100, 150, 3],
      //     },
      //     {
      //       heading: "Itens Reprovados",
      //       route: "/oficina-digital/reprovados",
      //       nivelCargo: [100, 150, 3],
      //     },
      //     {
      //       heading: "Histórico",
      //       route: "/oficina-digital/historico",
      //       nivelCargo: [100, 150, 3],
      //     },
      //   ],
      // },
      // {
      //   heading: "Bandeiras",
      //   route: "/historico-checklist",
      //   nivelCargo: [100, 150, 3],
      //   // fontIcon: "fa-calendar",
      //   svgIcon: "./media/icons/duotune/shopping/clipboard-list-dt.svg",
      // },
      // {
      //   heading: "Atendimentos",
      //   route: "/ordemservicogerenciar.php",
      //   nivelCargo: [-1, 2],
      //   // fontIcon: "fa-list",
      //   svgIcon: "./media/icons/duotune/text/txt009.svg",
      // },
      // {
      //   heading: "Resultados",
      //   route: "/pesquisadashboardlist.php",
      //   nivelCargo: [100, 150],
      //   // fontIcon: "fa-th-large",
      //   svgIcon: "./media/icons/duotune/shopping/star-half-alt.svg",
      // },

      // {
      //   sectionTitle: "Atividades",
      //   nivelCargo: [100, 150, 4, 5],
      //   route: "/oficina-digital",
      //   // fontIcon: "fa-desktop",
      //   svgIcon: "./media/icons/duotune/shopping/calendar-alt-dt.svg",
      //   sub: [
      //     {
      //       heading: "Quadro de Agendamentos",
      //       route: "/agenda-virtual/exibir-consultor",
      //       nivelCargo: [100, 150, 4, 5],
      //       menuID: "agenda-virtual-sublink-quadro-agendamento",
      //     },
      //     {
      //       heading: "Quadro de Programação",
      //       route: "/agenda-virtual/agendar",
      //       nivelCargo: [100, 150, 4],
      //     },
      //     {
      //       heading: "Quadro de Serviços",
      //       route: "/agenda-virtual/exibir",
      //       nivelCargo: [100, 150, 4, 5],
      //     },
      //     {
      //       heading: "Multiexibição Quadro de Serviços",
      //       route: "/agenda-virtual/exibir-multiview",
      //       nivelCargo: [100, 150, 4, 5],
      //       menuID: "agenda-virtual-sublink-multiview",
      //     },
      //   ],
      // },
      // {
      //   heading: "Veiculos",
      //   route: "/filter",
      //   nivelCargo: [100, 150],
      //   // fontIcon: "fa-th-large",
      //   svgIcon: "./media/icons/duotune/shopping/veiculos.svg",
      // },
      // {
      //   heading: "Relatórios",
      //   route: "/pesquisadashboardlist.php",
      //   nivelCargo: [100, 150],
      //   // fontIcon: "fa-th-large",
      //   svgIcon: "./media/icons/duotune/shopping/veiculos.svg",
      // },

      // // Rotas do indicar
      // {
      //   heading: "Dashboard",
      //   route: "/dashboard",
      //   nivelCargo: [],
      //   svgIcon: "./media/icons/duotune/graphs/gra008.svg",
      // },
      // {
      //   heading: "Lojas",
      //   route: "/lojas",
      //   nivelCargo: [100, 150],
      //   fontIcon: "fa-store",
      //   svgIcon: "./media/icons/duotune/general/store1.svg",
      // },
      // {
      //   heading: "Gestão da indicação",
      //   route: "/filtroVeiculos",
      //   nivelCargo: [100, 150],
      //   // fontIcon: "fa-chart-area",
      //   svgIcon: "./media/icons/duotune/general/cogs.svg",
      // },
      // {
      //   heading: "Gestão de produtos",
      //   route: "/gestaoLubrificantes",
      //   nivelCargo: [100, 150],
      //   fontIcon: "fa-chart-area",
      //   svgIcon: "./media/icons/duotune/general/box.svg",
      // },
      // {
      //   heading: "Informações dos veículos",
      //   route: "/veiculos",
      //   nivelCargo: [100, 150],
      //   fontIcon: "fa-chart-area",
      //   svgIcon: "./media/icons/duotune/general/car2.svg",
      // },
      // {
      //   heading: "Atendimentos",
      //   route: "/lojas/atendimentos?",
      //   nivelCargo: [100, 150],
      //   fontIcon: "fa-chart-area",
      //   svgIcon: "./media/icons/duotune/general/hands.svg",
      // },

      // Pontuei
      {
        sectionTitle: "Home",
        route: "/",
        nivelCargo: [100, 200],
        svgIcon: "./media/icons/duotune/general/home-duotone.svg",
        sub: [
          {
            heading: "Home",
            route: "/home",
            nivelCargo: [100, 200, 300, 400],
          },
        ],
      },
      {
        sectionTitle: "Cadastros",
        route: "/",
        nivelCargo: [100, 200, 300, 400, 500, 550],
        svgIcon: "./media/icons/duotune/general/clipboard-list-duotone.svg",
        sub: [
          {
            heading: "Companhias",
            route: "/companhias",
            nivelCargo: [100, 200],
          },
          {
            heading: "Lojas",
            route: "/lojas",
            nivelCargo: [100, 200, 300, 400, 500],
          },
          {
            heading: "Usuários",
            route: "/usuarios",
            nivelCargo: [100, 200, 300, 400, 500],
          },
          {
            heading: "Produtos",
            route: "/lojas/produtos",
            nivelCargo: [100, 200, 300, 400, 500],
          },
          {
            heading: "Vendedores",
            route: "/vendedores",
            nivelCargo: [100, 200, 300, 400, 500,],
          },
          {
            heading: "Consultores",
            route: "/consultores",
            nivelCargo: [100, 200, 300, 400, 500, 550],
          }
        ],
      },
      {
        sectionTitle: "Estoque",
        route: "/",
        nivelCargo: [100, 200, 300, 400, 500],
        svgIcon: "./media/icons/duotune/general/luggage-cart.svg",
        sub: [
          {
            heading: "Estoque",
            route: "/estoque",
            nivelCargo: [100, 200, 300, 400, 500],
          },
          {
            heading: "Estoque Expirado",
            route: "/estoque/expirado",
            nivelCargo: [100, 200, 300, 400, 500],
          }
        ],
      },
      {
        sectionTitle: "Remessas",
        route: "/",
        nivelCargo: [100, 200, 300, 400, 500, 600],
        svgIcon: "./media/icons/duotune/general/hand-holding-coin.svg",
        sub: [
          {
            heading: "Remessas",
            route: "/remessas",
            nivelCargo: [100, 200],
          },
          {
            heading: "Histórico",
            route: "/remessas/historico",
            nivelCargo: [100, 200],
          },
          {
            heading: "Prêmio",
            route: "/remessas/premio",
            nivelCargo: [100, 200],
          },
          {
            heading: "Vendas",
            route: "/vendas",
            nivelCargo: [100, 200, 300, 400, 500, 600],
          }
        ],
      },
      {
        sectionTitle: "Auxiliares",
        route: "/",
        nivelCargo: [100, 200],
        svgIcon: "./media/icons/duotune/general/clipboard.svg",
        sub: [
          {
            heading: "Categorias",
            route: "/categorias",
            nivelCargo: [100, 200],
          },
          {
            heading: "Produtos Auxiliares",
            route: "/produtos",
            nivelCargo: [100, 200],
          },
          {
            heading: "Produtos Fracionados",
            route: "/produtos-fracionados",
            nivelCargo: [100, 200]
          },
          {
            heading: "Perfis das Lojas",
            route: "/perfis-das-lojas",
            nivelCargo: [100, 200],
          },
          {
            heading: "Grupos",
            route: "/grupos",
            nivelCargo: [100, 200, 300, 400, 500],
          },
          {
            heading: "Gerência Regional",
            route: "/gestao-das-lojas",
            nivelCargo: [100, 200, 300, 400, 500],
          },
          {
            heading: "Catálogo de Prêmios",
            route: "/catalogo-de-premios",
            nivelCargo: [100, 200, 300, 400, 500],
          },
        ],
      },
      {
        sectionTitle: "Social",
        route: "/",
        nivelCargo: [100, 200],
        svgIcon: "./media/icons/duotune/general/ballon-chat-duotone.svg",
        sub: [
          {
            heading: "Social",
            route: "/social",
            nivelCargo: [100, 200],
          },
        ],
      },
      {
        sectionTitle: "Treinamentos",
        route: "/",
        nivelCargo: [100, 200],
        svgIcon: "./media/icons/duotune/general/user-graduate.svg",
        sub: [
          {
            heading: "Treinamentos",
            route: "/treinamentos",
            nivelCargo: [100, 200],
          },
        ],
      },
      {
        sectionTitle: "Área do Gestor",
        route: "/",
        nivelCargo: [100, 200],
        svgIcon: "./media/icons/duotune/general/user-tie-duotone.svg",
        sub: [
          {
            heading: "Notificações",
            route: "/notificacoes",
            nivelCargo: [100, 200],
          },
          {
            heading: "Conquistas",
            route: "/conquistas",
            nivelCargo: [100, 200],
          },
        ],
      },
      {
        sectionTitle: "Relatórios",
        route: "/",
        nivelCargo: [100, 200],
        svgIcon: "./media/icons/duotune/graphs/gra008.svg",
        sub: [
          {
            heading: "Vendas",
            route: "/relatorio/vendas",
            nivelCargo: [100, 200],
          },
          {
            heading: "Resgates",
            route: "/relatorio/resgates",
            nivelCargo: [100, 200],
          },
          {
            heading: "Notas Fiscais",
            route: "/relatorio/notas-fiscais",
            nivelCargo: [100, 200],
          }
        ],
      },
      {
        sectionTitle: "Área do Desenvolvedor",
        route: "/",
        nivelCargo: [100],
        svgIcon: "./media/icons/duotune/general/developer-duotone.svg",
        sub: [
          {
            heading: "Conquistas Auxiliares",
            route: "/conquistas-auxiliares",
            nivelCargo: [100],
          },
          {
            heading: "Logs de Estoque",
            route: "/logs/estoque",
            nivelCargo: [100],
          },
          {
            heading: "Logs de Remessas",
            route: "/logs/remessas",
            nivelCargo: [100],
          },
          {
            heading: "Logs de Push Notification",
            route: "/logs/push-notification",
            nivelCargo: [100],
          },
          {
            heading: "Lista de vendedores",
            route: "/lista/vendedores",
            nivelCargo: [100],
          },
        ],
      },
      {
        heading: "Sair",
        route: "/",
        nivelCargo: [100, 200, 300],
        svgIcon: "./media/icons/duotune/general/right-from-bracket-solid.svg"
      }
    ],
  },
];

export default DocMenuConfig;
