
import { defineComponent, ref, onMounted, computed, DefineComponent, watch, onBeforeMount } from "vue";
import KTMenu from "@/layout/header/Menu.vue";
import KTMenuDropdown from "@/layout/header/MenuDropdown.vue";
import { useStore } from "vuex";
import {
  headerWidthFluid,
  headerLeft,
  asideDisplay,
} from "@/core/helpers/config";
import ModalFiltro from "./ModalFiltro.vue";
import { Modal } from "bootstrap";
import { useRoute } from "vue-router";
import axios from "axios";
import { useFilterMainStore } from "@/store/FilterMainStore";
import { useCompanyStore } from "@/store/CompanyStore";
import { useStoreStore } from "@/store/StoreStore";
import { useAuthStore } from "@/store/AuthStore";

export default defineComponent({
  name: "KTHeader",
  components: {
    KTMenu,
    KTMenuDropdown,
  },
  setup() {
    const store = useStore();
    const isMobile = ref(false);
    const modalName = ref('')
    const route = useRoute()
    const filterMainStore = useFilterMainStore()
    const companyStore = useCompanyStore()
    const storeStore = useStoreStore()
    const authStore = useAuthStore()
    const userName = ref("")
    const userRoleDesc = ref("")
    const userRoleLevel = ref("")
    const showMenu2 = ref(false)
    const headerTitle = ref("")

    /** Functions */
    // function handleHeaderTitle() {
    //   modalName.value = `${handlerUndefinedCompany()} ${handlerUndefinedCompany() && '>'} ${storeStore.getFantasyName}`
    //   // if(filterMainStore.getPathName === "/competidores" && storeStore.getId) {
    //   //   modalName.value = `${storeStore.getFantasyName} > ${storeStore.getFantasyName}`
    //   // }
    //   // if(filterMainStore.getPathName === "/lojas" && companyStore.getId) {
    //   //   modalName.value = companyStore.company.fantasy_name
    //   // }
    //   // if(filterMainStore.getPathName.includes("/lojas/produtos") && storeStore.getId) {
    //   //   modalName.value = storeStore.getFantasyName
    //   // }
    //   // if(filterMainStore.getPathName === "/estoque" && storeStore.getId) {
    //   //   modalName.value = storeStore.getFantasyName
    //   // }
    //   // if(filterMainStore.getPathName === "/remessas" && storeStore.getId) {
    //   //   modalName.value = storeStore.getFantasyName
    //   // }
    //   // if((filterMainStore.getPathName.includes("-fracionado")) && companyStore.getId) {
    //   //   modalName.value = companyStore.company.fantasy_name
    //   // }
    //   if((filterMainStore.getPathName.includes("perfis-das-lojas")) && companyStore.getId) {
    //     modalName.value = handlerUndefinedCompany()
    //   }
    //   if((filterMainStore.getPathName.replace('/', '') === "produtos") && companyStore.getId) {
    //     modalName.value = handlerUndefinedCompany()
    //   }
    //   if((filterMainStore.getPathName.includes("-fracionado")) && companyStore.getId) {
    //     modalName.value = handlerUndefinedCompany()
    //   }
    //   if((filterMainStore.getPathName.includes("categorias")) && companyStore.getId) {
    //     modalName.value = handlerUndefinedCompany()
    //   }
    //   if((filterMainStore.getPathName.includes("companhia")) && companyStore.getId) {
    //     modalName.value = handlerUndefinedCompany()
    //   }
    //   if((filterMainStore.getPathName.includes("social")) && companyStore.getId) {
    //     modalName.value = handlerUndefinedCompany()
    //   }
    //   if((filterMainStore.getPathName.includes("treinamentos")) && companyStore.getId) {
    //     modalName.value = handlerUndefinedCompany()
    //   }
    //   if((filterMainStore.getPathName.includes("lojas")) && companyStore.getId) {
    //     modalName.value = handlerUndefinedCompany()
    //   }
    // }

    // function handlerUndefinedCompany() {
    //   return companyStore?.company?.fantasy_name === undefined ? '' : companyStore?.company?.fantasy_name
    // }

    async function handleHeaderTitleV2() {
      headerTitle.value = ""
      const company = companyStore.getCompany
      const store = storeStore.getStore
      const pathList = [ "lojas/pro", "estoq", "usuario", "vended", "consult", "venda" ]

      if (pathList.some(path => route.path.includes(path))) {
        if(company?.fantasy_name) {
          headerTitle.value = company.fantasy_name
          if(store?.fantasy_name)
            headerTitle.value += ` > ${store.fantasy_name}`
        }
      } else {
        if(company?.fantasy_name)
          headerTitle.value = company.fantasy_name
      }
    }

    /** Life Cycles */
    onMounted(() => {
      // handleHeaderTitle()
      handleHeaderTitleV2()
      let userAgent = navigator.userAgent || navigator.vendor;

      if (userAgent.match(/Android/i) || userAgent.match(/iPhone/i))
        isMobile.value = true;
      else
        isMobile.value = false;

      if(authStore && authStore.getUser && authStore.getUser.name)
        userName.value = authStore.getUser.name

      if(authStore && authStore.getUser && authStore.getUser.role && authStore.getUser.role.description)
        userRoleDesc.value = authStore.getUser.role.description
      
      if(authStore && authStore.getUser && authStore.getUser.role && authStore.getUser.role.level)
        userRoleLevel.value = authStore.getUser.role.level
    });

    // watch(() => { companyStore.company, storeStore.getFantasyName, filterMainStore.getPathName }, () => handleHeaderTitle(), { deep: true })

    watch(() => { companyStore.getCompany, storeStore.getStore, route.path }, async() => handleHeaderTitleV2(), { deep: true })

    return {
      // headerTitle: modalName,
      headerTitle,
      headerWidthFluid,
      headerLeft,
      asideDisplay,
      isMobile,
      userName,
      userRoleDesc,
      userRoleLevel,
      showMenu2
    };
  },
});
